import config from '@/config/env-constants';
import _ from 'lodash';


function isDefaultCondition(condition) {
    return condition === 'good' || condition === 'damaged';
}

function getDefaultConditions() {
    return [
        {
            id: 'good',
            condition: 'Good',
            description: 'Default',
            isActive: true
        },
        {
            id: 'damaged',
            condition: 'Damaged',
            description: 'Default',
            isActive: true
        }
    ]
}

function getCodeName(prefix) {
    if (prefix && prefix.length > 0) {
        return prefix + '-Series';
    }
    return '';
}

function getAssetType(allAssetTypesObj, assetCode) {
    let assetTypeObj = {};

    let customAssetTypes = _.filter(allAssetTypesObj, o => {
        return o.assetTagging === config.assetTagging.CUSTOM;
    });

    if (!_.isEmpty(customAssetTypes)) {
        for (const assetType of customAssetTypes) {
            let regex = new RegExp(assetType.regex);
            if (regex.test(assetCode)) {
                assetTypeObj = assetType;
                break;
            }
        }
    }

    return assetTypeObj;
}

function cleanupFields(assetType) {
    let cleanedObj = { ...assetType };

    delete cleanedObj['Categories'];
    delete cleanedObj['Conditions'];
    delete cleanedObj['Test Values'];
    delete cleanedObj['status'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];

    return cleanedObj;
}


export const AssetTypeUtil = {
    isDefaultCondition,
    getDefaultConditions,
    getCodeName,
    getAssetType,
    cleanupFields
}